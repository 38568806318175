<!-- 打印机管理 -->

<template>
  <div class="printer-manage main-cnt">
    <div class="title">打印机列表</div>
    <div class="content">
      <common-table ref="printerTable" tableHeight="calc(100vh - 325px)" :apiName="BasicApi.getPrintersLists"
        :columns="tableColumns" @onEdit="onEdit">
        <template #operate>
          <el-button type="primary" round @click="onClickBtn"
            v-if="authData.indexOf('r_TIBhjRRUPzE6pfLjvmGLg8GDFjYE') != -1">
            新增打印机
          </el-button>
        </template>

        <template #printer_status="{ row }">
          <div class="status-text" :class="statusColors(row.crp_online_status)">{{ row.printer_status_text }}</div>
        </template>
      </common-table>
    </div>

    <!-- 新增/编辑 打印机弹框 -->
    <w-dialog ref="refDialog" class="add-dialog" :title="isEdit ? '编辑打印机' : '新增打印机'" width="40%"
      :confirmText="isEdit ? '提交保存' : '确认新增'" btnWidth="140px" top="10vh" @wConfirm="confirm">
      <el-form class="add-form" ref="refForm" :model="ruleForm" :rules="rules" labelPosition="top">
        <el-form-item label="打印机名称" prop="crp_name">
          <el-input v-model="ruleForm.crp_name" placeholder="请输入打印机名称" clearable></el-input>
        </el-form-item>

        <el-form-item label="打印机编号" prop="crp_sn">
          <el-input v-model="ruleForm.crp_sn" placeholder="请输入打印机编号" clearable></el-input>
        </el-form-item>

        <el-form-item label="关联门店" prop="store">
          <el-cascader v-if="isShowProvince" v-model="ruleForm.store" :props="storeCodeProps" clearable
            placeholder="请选择关联门店" />
        </el-form-item>
      </el-form>
    </w-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed, watch, nextTick, } from "vue";
import { ElMessage, } from "element-plus";
import { BasicApi, } from "@/plugins/api.js";
import { useStore, } from "vuex";

const store = useStore();
const menuTokens = computed(() => store.state.menuToken.menuTokens);
const authData = ref([]);
watch(
  () => menuTokens.value,
  (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);

const printerTable = ref(null);  // 列表对象
const refDialog = ref(null);  // 新增、编辑对象
const isEdit = ref(false);  // 是否编辑
const refForm = ref(null);  // 表单对象
const storeData = ref([]);  // 门店数据
const isShowProvince = ref(false);  // 重新加载Cascader 级联选择器
const currentRow = ref('');  // 当前行数据
// 表格配置数据
const tableColumns = ref([
  {
    prop: "crp_name",
    label: "打印机名称",
  },
  {
    prop: "crp_sn",
    label: "打印机编号",
  },
  {
    prop: "shopname",
    label: "关联门店",
  },
  {
    prop: "printer_status",
    label: "状态",
    type: "customRender",
    minWidth: 100,
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 100,
    bottons: [
      {
        name: "编辑",
        action: "onEdit",
        token: "r_TDpmZxm0R5dxHR9jQyXkVlRUacaU",
        HiddenKey: "crp_online_status",
        showValue: [1],
        className: "theme-font-btn",
      },
    ],
  },
]);
// 表单数据对象
const ruleForm = ref({
  crp_name: '', // 打印机名称
  crp_sn: '', // 打印机编号
  store: [],  // 门店
});
/** 表单规则对象 */
const rules = reactive({
  crp_name: [
    { required: true, message: "请输入打印机名称", trigger: "blur", },
  ],
  crp_sn: [
    { required: true, message: "请输入打印机编号", trigger: "blur", },
  ],
});
/**
 * 
 *  获取关联门店
 * 
 */
const storeCodeProps = {
  lazy: true,
  lazyLoad(node, resolve) {
    const { level } = node;
    let param = level == 0 ? '' : node.value;
    BasicApi.getShopLists({
      crp_shop_type: param,
    }).then((res) => {
      if (res.Code === 200) {
        let nodes = [];
        if (level == 0) {
          if (res.Data.shop_type.length > 0) {
            nodes = res.Data.shop_type.map((val) => {
              return {
                value: val.crp_shop_type,
                label: val.name,
                leaf: level >= 1,
              }
            })
          }
        }
        storeData.value = res.Data.shop_list;
        if (res.Data.shop_list.length > 0) {
          nodes = res.Data.shop_list.map((val) => {
            return {
              value: val.crp_shop_id,
              label: val.shop_name,
              leaf: level >= 1,
            }
          })
        }
        resolve(nodes);
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
}
/**
 * 
 *  新增打印机
 * 
 */
const onClickBtn = () => {
  isEdit.value = false;
  refDialog.value.show();
  ruleForm.value = {
    crp_name: '', // 打印机名称
    crp_sn: '', // 打印机编号
    store: [],  // 门店
  };
  nextTick(() => {
    isShowProvince.value = true;
  })
};
/**
 * 
 *  编辑打印机
 * 
 */
const onEdit = (row) => {
  currentRow.value = row;
  isEdit.value = true;
  refDialog.value.show();
  ruleForm.value.crp_name = row.crp_name;
  ruleForm.value.crp_sn = row.crp_sn;
  if (row.crp_shop_id == 0) {
    ruleForm.value.store = [];
  } else {
    ruleForm.value.store = [row.crp_shop_type, row.crp_shop_id];
  }
  nextTick(() => {
    refForm.value.resetFields();
    isShowProvince.value = true;
  })
}
/**
 * 
 *  确定按钮
 * 
 */
function confirm() {
  let params = {
    crp_name: ruleForm.value.crp_name,
    crp_sn: ruleForm.value.crp_sn,
  };
  if (ruleForm.value.store && ruleForm.value.store.length > 0) {
    params.crp_shop_type = ruleForm.value.store[0];
    params.crp_shop_id = ruleForm.value.store[1];
    const result = storeData.value.find(item => item.crp_shop_id === ruleForm.value.store[1]);
    params.crp_pid = result.crp_pid;
  } else {
    params.crp_shop_type = '';
    params.crp_shop_id = '';
    params.crp_pid = '';
  }
  let url = '';
  if (isEdit.value) {
    // 编辑
    url = "printersEdit";
    params.crp_id = currentRow.value.crp_id;
  } else {
    //  新增
    url = "printersAdd";
  }
  // console.log("params", url, params);

  refDialog.value.isLoading = true;
  BasicApi[url](params).then((res) => {
    refDialog.value.isLoading = false;
    if (res.Code === 200) {
      ElMessage.success(`操作成功！`);
      refDialog.value.close();
      printerTable.value.tableLoad();
    } else {
      ElMessage.error(res.Message);
    }
  });
}
/**
 * 
 *  状态样式
 * 
 */
const statusColors = (status) => {
  let status_color = '';  // 0-离线 1-在线正常 2-在线异常
  switch (status) {
    case 0:
      status_color = 'btn-grey';
      break;
    case 1:
      status_color = 'btn-green';
      break;
    case 2:
      status_color = 'btn-red';
      break;
  }
  return status_color;
}

onMounted(() => {
  printerTable.value.tableLoad();
});
</script>

<style lang="scss">
.printer-manage {
  font-family: "Source Han Sans CN";

  .content {
    padding: 20px;
  }

  .add-dialog {
    .el-dialog {
      min-width: 840px;

      .el-dialog__body {
        padding: 10px 15px 50px;

        .add-form {
          display: flex;
          flex-wrap: wrap;

          .el-form-item {
            width: 50%;
            padding: 20px 15px 0;
            margin-bottom: 0;

            .el-form-item__content {
              .el-select {
                width: 100%;

                .el-input {
                  width: 100%;
                }
              }
            }
          }

          .el-form-item.upload {
            width: 100%;
          }

          .el-divider {
            margin: 30px 0 0 15px;
            width: calc(100% - 30px);
          }
        }
      }
    }
  }

}
</style>
